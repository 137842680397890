import React, { Fragment, useEffect, useState } from 'react'
import moment from "moment";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { oddController } from "../controllers/oddsController/oddController";
import AnalysisModal from './components/AnalysisModal';
import color from '../config/color';
import Loading from './components/Loading';
import PurchaseBettingModal from './components/PurchaseBettingModal';

const BodyLiveData = ({ t, i18n }) => {
    const defaultDate = moment(new Date()).format("YYYY-MM-DD");
    const [username, setUsername] = useState("");
    const history = useHistory();
    const [startDate, setStartDate] = useState(defaultDate);
    const rowsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState();
    const [searchText, setSearchText] = useState([]);
    const [searchSingle, setSearchSingle] = useState([]);
    const [singleLiveData, setSingleLiveData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [rapidEventId, setRapidEventId] = useState(0);
    const [eventList, setEventList] = useState([]);
    const userId = localStorage.getItem("userId");
    console.log("userid",userId)

    useEffect(() => {
        const userName = localStorage.getItem("userName");
        const userRole = localStorage.getItem("userRole");
        if (userName == undefined || userRole > 2) {
            history.push("/");
        }
        setUsername(userName);
        getGetBodyLiveData();
    }, []);

    const getGetBodyLiveData = () => {
        setLoading(true);
        const userId = localStorage.getItem("userId");
        oddController.getSingleLiveData(startDate, true, parseInt(userId), (data) => {
            //console.log("dsta",data)
            setSearchSingle(data.livedata);
            setSingleLiveData(data.livedata);
            setPage(data.livedata.length / rowsPerPage);
            setLoading(false);
        });
    }

    const handlePageClick = async (data) => {
        // console.log(data.selected);
        setPageCount(data.selected);
    };

    const handleTeamChange = (e) => {
        setSearchText(e.target.value);
        const columnNameHome = i18n.language === "mm" ? 'homeTeamMyan' : 'homeTeam';
        const columnNameAway = i18n.language === "mm" ? 'awayTeamMyan' : 'awayTeam';
        if (e.target.value.length != 0) {
            const filteredRows = searchSingle.filter((row) => {
                return (
                    row[columnNameHome].toLowerCase().includes(e.target.value.toLowerCase()) ||
                    row[columnNameAway].toLowerCase().includes(e.target.value.toLowerCase())
                );
            });
            setSearchSingle(filteredRows);
            setPage(filteredRows.length / rowsPerPage);
        }
        else {
            setSearchSingle(singleLiveData);
            setPage(singleLiveData.length / rowsPerPage);
        }
    }

    const handleBettingPurchase = (d, type, max) => {
        console.log("main data", d);
        let obj = {};

        if (type === "bodyDiff") {
            obj = {
                rapidEventId: d.rapidId,
                homeTeamId: d.homeTeamId,
                awayTeamId: d.awayTeamId,
                homeTeam: d.homeTeam,
                homeTeamMyan:d.homeTeamMyan,
                awayTeamMyan:d.awayTeamMyan,
                awayTeam: d.awayTeam,
                underId: d.underTeamId,
                overId: d.overTeamId,
                selectedDate:d.selectedDate,
                isFivePercentCom:d.isFivePercentCom,
                choiceBetType: "Body",
                bodyOdds : d.bodyOdds,
                goalOdds : d.goalOdds,
                choiceTeam: d.maxBody === d.homeTeam ? "Home" : "Away",
                amount : d.bodyAmount
            }
        }
        else if (type === "goalDiff") {
            obj = {
                rapidEventId: d.rapidId,
                homeTeamId: d.homeTeamId,
                awayTeamId: d.awayTeamId,
                homeTeamMyan:d.homeTeamMyan,
                awayTeamMyan:d.awayTeamMyan,
                homeTeam: d.homeTeam,
                awayTeam: d.awayTeam,
                underId: d.underTeamId,
                overId: d.overTeamId,
                selectedDate:d.selectedDate,
                isFivePercentCom:d.isFivePercentCom,
                choiceBetType: "Goal",
                bodyOdds : d.bodyOdds,
                goalOdds : d.goalOdds,
                choiceTeam: d.maxGoal,
                amount : d.goalAmount
            }
        }
        else {
            obj = {
                rapidEventId: d.rapidId,
                homeTeamId: d.homeTeamId,
                awayTeamId: d.awayTeamId,
                homeTeamMyan:d.homeTeamMyan,
                awayTeamMyan:d.awayTeamMyan,
                homeTeam: d.homeTeam,
                awayTeam: d.awayTeam,
                underId: d.underTeamId,
                overId: d.overTeamId,
                selectedDate:d.selectedDate,
                bodyOdds : d.bodyOdds,
                goalOdds : d.goalOdds,
                isFivePercentCom:d.isFivePercentCom,
            }
        }

        setEventList([obj]);
    }

    return (
        <div>
            <PurchaseBettingModal t={t} userId={userId} eventList={eventList} editData={null} seteditData={null} i18n={i18n}/>
            <AnalysisModal rapidEventId={rapidEventId} t={t} />
            <div className="mb-2 d-flex justify-content-between align-items-center flex-wrap" style={{ gap: 5 }}>
                <div className="">
                    <input
                        className="custom-input"
                        type="date"
                        id="birthday"
                        style={{ fontSize: '0.8rem', height: 32, width: 150 }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        name="birthday"
                    />
                    <button
                        type="button"
                        className="btn"
                        style={{ backgroundColor: color['dark'].main, color: '#fff', fontSize: '0.8rem', marginLeft: 5 }}
                        onClick={() => getGetBodyLiveData()}
                    >
                        {t('search')}
                    </button>
                </div>
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => handleTeamChange(e)}
                    className="custom-input"
                    style={{ height: 35, width: 200 }}
                    id="exampleFormControlInput1"
                    placeholder="search ..."
                />
            </div>
            {isLoading ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Loading />
                    <p>Loading .....</p>
                </div>
            ) : (
                <Fragment>
                    <div className="table-responsive">
                        <table className="table">
                            <thead style={{ fontSize: '0.85rem', backgroundColor: color['dark'].headerbg }}>
                                <tr>
                                    <th scope="col">{t('no')}</th>
                                    <th scope="col">{t('eventTime')}</th>
                                    <th scope="col">{t('team')}</th>
                                    <th scope="col"></th>
                                    <th scope="col">{t('homeAmt')}</th>
                                    <th scope="col">{t('awayAmt')}</th>
                                    <th scope="col">{t('overAmt')}</th>
                                    <th scope="col">{t('underAmt')}</th>
                                    <th scope="col">{t('bodyDiff')}</th>
                                    <th scope="col">{t('goalDiff')}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: '0.8rem' }}>
                                {searchSingle.length != 0 ?
                                    searchSingle &&
                                    searchSingle
                                        .slice(
                                            pageCount * rowsPerPage,
                                            pageCount * rowsPerPage + rowsPerPage
                                        )
                                        .map((d, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <tr >
                                                        <th scope="row">{pageCount * rowsPerPage + i + 1}</th>
                                                        <td>{moment(d.eventTime).format("hh:mm A")}</td>
                                                        <td>
                                                            <span style={{ color: d.overTeamId === d.homeTeamId ? color['dark'].secondary6 : null, marginRight: 5 }}>
                                                                {i18n.language === "mm" ? d.homeTeamMyan : d.homeTeam}
                                                            </span>
                                                            Vs
                                                            <span style={{ color: d.overTeamId === d.awayTeamId ? color['dark'].secondary6 : null, marginLeft: 5 }}>
                                                                {i18n.language === "mm" ? d.awayTeamMyan : d.awayTeam}
                                                            </span> <br />
                                                            ({d.bodyOdds})/({d.goalOdds})
                                                        </td>
                                                        <td>
                                                        <div style={{ display: 'flex', flexDirection: 'column',fontSize:10,gap:5 }}>
                                                          <span>({t('sellAmt')})</span>
                                                          <span>({t('buyAmt')})</span>
                                                        </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.homeAmount > d.awayAmount ? color['dark'].secondary6 : 'black' }}>
                                                                    {d.homeAmount.toLocaleString("en-US")}
                                                                </span>
                                                                <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                    {d.purHomeAmount === 0 ? '-' : d.purHomeAmount.toLocaleString("en-US")}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.awayAmount > d.homeAmount ? color['dark'].secondary6 : 'black' }}>
                                                                    {d.awayAmount.toLocaleString("en-US")}
                                                                </span>
                                                                <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                    {d.purAwayAmount === 0 ? '-' : d.purAwayAmount.toLocaleString("en-US")}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.over > d.under ? color['dark'].secondary6 : 'black' }}>
                                                                    {d.over.toLocaleString("en-US")}
                                                                </span>
                                                                <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                    {d.purOverAmount === 0 ? '-' : d.purOverAmount.toLocaleString("en-US")}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.under > d.over ? color['dark'].secondary6 : 'black' }}>
                                                                    {d.under.toLocaleString("en-US")}
                                                                </span>
                                                                <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                    {d.purUnderAmount === 0 ? '-' : d.purUnderAmount.toLocaleString("en-US")}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td style={{ color: color['dark'].secondary5,fontWeight:'bold' }}>
                                                            {
                                                                userId == 4 ? d.bodyAmount === 0 ?
                                                                    <span>
                                                                        {"-"}<br />
                                                                        {d.bodyAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : <span
                                                                        data-bs-target="#purchaseBettingModal"
                                                                        data-bs-toggle="modal"
                                                                        onClick={() => handleBettingPurchase(d, "bodyDiff")}
                                                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                                    >
                                                                        {i18n.language === "mm" ? d.maxBodyMyan : d.maxBody}<br />
                                                                        {d.bodyAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    :  
                                                                    <span>
                                                                    {i18n.language === "mm" ? d.maxBodyMyan : d.maxBody}<br />
                                                                    {d.bodyAmount.toLocaleString("en-US")}
                                                                </span>

                                                            }
                                                        </td>
                                                        <td style={{ color: color['dark'].secondary5,fontWeight:'bold' }}>
                                                            {
                                                                userId == 4 ? d.goalAmount === 0 ?
                                                                    <span>
                                                                        {"-"}<br />
                                                                        {d.goalAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : <span
                                                                        data-bs-target="#purchaseBettingModal"
                                                                        data-bs-toggle="modal"
                                                                        onClick={() => handleBettingPurchase(d, "goalDiff")}
                                                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                                    >
                                                                        {i18n.language === "mm" ? d.maxGoalMyan : d.maxGoal}<br />
                                                                        {d.goalAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : 
                                                                    <span>
                                                                     {i18n.language === "mm" ? d.maxGoalMyan : d.maxGoal}<br />
                                                                    {d.goalAmount.toLocaleString("en-US")}
                                                                </span>
                                                            }
                                                        </td>
                                                        <td>
                                                            <button
                                                                data-bs-target="#purchaseBettingModal"
                                                                data-bs-toggle="modal"
                                                                style={{ backgroundColor: color['dark'].main, color: '#fff',marginRight:5 }}
                                                                className='btn btn-sm mt-2'
                                                                onClick={() => handleBettingPurchase(d)}>
                                                                <i className="fa-solid fa-upload"></i>&nbsp;{t('purchase')}
                                                            </button>
                                                            <button
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#analysisModal"
                                                                style={{ backgroundColor: color['dark'].main, color: '#fff' }}
                                                                className='btn btn-sm mt-2'
                                                                onClick={() => setRapidEventId(d.rapidId)}>
                                                                <i className="fa fa-eye"></i>&nbsp;{t('view')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            )
                                        })
                                    : <tr>
                                        <td colSpan={9} style={{ textAlign: 'center' }}>{t('nodata')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        previousLabel={t('previous')}
                        nextLabel={t('next')}
                        breakLabel={"..."}
                        pageCount={page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </Fragment>
            )}
        </div>
    )
}

export default BodyLiveData;


